import { Component, Input } from '@angular/core'

import { OptionModel } from 'src/app/views/companies/model'

@Component({
  selector: 'app-company-name-or-selector',
  templateUrl: './company-name-or-selector.component.html',
  styleUrls: ['./company-name-or-selector.component.scss']
})
export class CompanyNameOrSelectorComponent {
  @Input() companyNameOptions: OptionModel[] = []
}
