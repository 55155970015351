<mat-card [class]="containerClasses" fxFlex>
  <mat-card-title fxLayout="row">

    <span class="card-title" fxFlex>
      <ng-content select="[card-title]"></ng-content>
    </span>

    <app-menu fxFlex="10"
              *ngIf="contextMenuItems && contextMenuItems.length > 0"
              [menuOptions]="contextMenuItems"
              [attr.data-title]="cardTitle || null"
              (clickOption)="onClickContextMenuItem($event)"
    ></app-menu>

  </mat-card-title>

  <mat-card-content>
    <ng-content select="[card-body]"></ng-content>
  </mat-card-content>
</mat-card>
