<mat-select
  #matSelect
  panelClass="company-select-panel"
  placeholder="{{ 'COMPANY' | translate }}"
  [formControl]="selectFormControl"
  (selectionChange)="onSelectionChange()"
  disableOptionCentering
  (openedChange)="onToggleSelect($event)"
>
  <div class="box-search">
    <input
      #searchInput
      [formControl]="searchControl"
      type="text"
      placeholder="{{ 'COMPANY_NAME_PLACEHOLDER' | translate }}"
      (input)="filterItem(searchInput.value)"
      (keydown)="onInputKeyDown($event)"
    />
    <div class="box-search-icon" (click)="clearSearch()">
      <button mat-icon-button class="clear-button">
        <mat-icon class="mat-24" aria-label="Clear icon">clear</mat-icon>
      </button>
    </div>
  </div>

  <div
    class="option-wrapper"
    #optionWrapper
    *ngIf="filteredOptions && filteredOptions.length > 0; else emptyOption"
  >
    <mat-option
      *ngFor="let option of filteredOptions; trackBy: trackByValueField"
      [value]="option[valueField]"
    >
      {{ option[displayField] }}
    </mat-option>
  </div>

  <ng-template #emptyOption>
    <mat-option disabled="true">-- {{ 'EMPTY_SEARCH_RESULT' | translate }} --</mat-option>
  </ng-template>
</mat-select>
