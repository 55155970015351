<div class="ect2-app">
  <zui-header [logoTitle]="region === 'EU' ? '../../../assets/icons/conti-logo.svg' : '../../../assets/icons/na-logo.svg'"
    [useEmailAsName]="true"
    [hasShowChangeCompany]="false"
    [showChangeCompanyDialogOnMobile]="false"></zui-header>
  <router-outlet></router-outlet>
</div>
<div class="ect2-footer">
  <zui-footer [logoSelector]="logoSelector"
              [links]="links"
              [copyRightText]="copyRightText"></zui-footer>
</div>
