export enum OwnerType {
  COMPANY = 'COMPANY',
  USERPROFILE = 'USERPROFILE',
  USER = 'USER'
}

export enum SettingStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum UserSettings {
  SELECTED_COMPANY = 'SELECTED_COMPANY',
}
