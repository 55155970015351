import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { MenuOption } from '../menu/menu.component'

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() contextMenuItems: MenuOption[] = []
  @Input() containerClasses: string[] = []
  @Input() hasContextMenu = false
  @Input() cardTitle = ''
  @Output() clickedItem = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  onClickContextMenuItem(item: string) {
    this.clickedItem.emit(item)
  }
}
