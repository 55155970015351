import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSelect } from '@angular/material/select'
import { Store } from '@ngxs/store'

import { SetActiveCompanyId } from 'src/app/app.state'
import { OptionModel } from 'src/app/views/companies/model'

/**
 * Select component to choose current company
 */
@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnChanges {

  /**
   * Key value used to display in the select option
   */
  @Input() displayField: string = 'name'
  /**
   * Key value used to get the option final value
   */
  @Input() valueField: string = 'id'
  /**
   * List of available option
   */
  @Input() options: OptionModel[]
  @Input() selectedCompanyId: string = ''

  /**
   * Emit event when the select is changed
   */
  @Output() changed = new EventEmitter<string>()

  filteredOptions: OptionModel[] = []

  selectFormControl: FormControl = new FormControl()
  searchControl: FormControl = new FormControl('')

  @ViewChild('matSelect') private selectElement: MatSelect
  @ViewChild('searchInput') private searchInput: ElementRef
  @ViewChild('optionWrapper') private optionWrapper: ElementRef

  inputLostFocusKeys = [
    'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter'
  ]
  inputIgnoreKey = ['']

  private expectedOptionHeight = 48

  get selectedValue() { return this.selectFormControl.value }

  constructor(private store: Store) { }

  ngOnChanges(changes: SimpleChanges): void {
    const companyChange = changes.selectedCompanyId
    if (companyChange && companyChange.currentValue !== companyChange.previousValue) {
      this.selectFormControl.setValue(changes.selectedCompanyId.currentValue)
      this.store.dispatch(new SetActiveCompanyId(companyChange.currentValue))
    }
    this.filteredOptions = this.options
  }

  onSelectionChange() {
    this.changed.emit(this.selectedValue)
  }

  onInputKeyDown(event: KeyboardEvent) {
    // This handler support user interaction with only keyboard,
    // so after search they can select the searched items using
    // arrow key
    const isLostFocusKey = this.inputLostFocusKeys.includes(event.key) && event.key !== ' '

    if (!isLostFocusKey) {
      event.stopPropagation()
    }

    if (isLostFocusKey && this.filteredOptions.length > 0) {
      this.focusSelect()
    }
  }

  onToggleSelect(isToggle: boolean) {
    if (isToggle) {
      // Because we wrapped the mat-options inside a div, next time user
      // open the select box, it will not automatically jump to the last
      // selection anymore, we need to do it ourself
      this.setScrolling()
      this.focusInput()
    } else {
      // We need to clear search when out-focused due to problem
      // with retaining the selected value when user try to search
      // but with no result, the old selected value will not be displayed
      // to user anymore
      this.clearSearch()
    }
  }

  filterItem(value: string) {
    this.filteredOptions = this.filterOptionsByValue(value)
  }

  trackByValueField(_, item: OptionModel): string | number {
    return item[this.valueField]
  }

  focusInput() {
    this.searchInput.nativeElement.focus()
  }

  public clearSearch() {
    this.filterItem('')
    this.searchControl.setValue('')
  }

  private setScrolling() {
    const selectedIndex = this.filteredOptions.findIndex(item => item.id === this.selectedValue)
    this.optionWrapper.nativeElement.scroll(0, this.expectedOptionHeight * selectedIndex)
  }

  private filterOptionsByValue(value: string): OptionModel[] {
    return this.options.filter(
      item => item[this.displayField].toLowerCase().indexOf(value.trim().toLowerCase()) > -1
    )
  }

  private focusSelect() {
    // There should be an easier way to focus the select with
    // highlighed option, but for now a close and open is the
    // most consistent solution
    this.selectElement.close()
    this.selectElement.open()

    this.selectElement.focus()
  }
}
