import { Observable, of } from 'rxjs'
import { ICompany } from '@zonar-ui/auth/lib/models/company.model'

export function getCompany(company: any): Observable<ICompany> {
  if (typeof company === 'object') {
    return of(company)
  } else {
    return of({
      id: company,
      name: '',
    })
  }
}
