<app-spinner id="loading-companies" [shouldLoading]="shouldLoadCompanyList$">
  <div *ngIf="shouldLoadCompanyList$ | async" class="companies" [ngClass]="{'company': options && options.length === 1}">
    <div *ngIf="companyList$ | async as companyList">
      <app-company-name-or-selector [companyNameOptions]="options">
        <app-company-selector [selectedCompanyId]="currentCompanyId" [options]="options"
          (changed)="switchCompany($event)" [title]="companyTitle">
        </app-company-selector>
      </app-company-name-or-selector>

      <app-update-config-group-button
        [isSaving]="isSaving"
        [isTesting]="isTesting"
        [isDeploying]="isDeploying"
        [isUpdatingConfiguration]="isUpdatingConfiguration"
        [isValidatedConfiguration]="isValidatedConfiguration"
        (shouldSaveConfiguration)="validateDataConfigurationOnSave()"
        (shouldPublishConfiguration)="openConfirmDialog(CONFIRM_DIALOG_TYPE.PUBLISH)"
        (shouldUndoConfiguration)="undoConfigurationChanged()"
        (shouldTestConfiguration)="openConfirmDialog(CONFIRM_DIALOG_TYPE.TEST)"></app-update-config-group-button>

    </div>

    <div class='filter-divider'></div>
    <app-spinner [shouldLoading]="shouldLoading$">
      <div *ngIf="shouldLoading$ | async">
        <app-navigation-tab [tabLinks]="tabLinks" (tabIndex)="switchTab($event)"></app-navigation-tab>
        <app-configuration-layout *ngIf="currentCompanyId"></app-configuration-layout>
      </div>
    </app-spinner>
  </div>
</app-spinner>
